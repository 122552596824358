export default [
    {
        key: 'quick-links',
        type: 'menu',
        groups: [
            {
                items: [
                    {
                        title: 'Quick Links',
                        type: 'menu_item_header',
                    },
                    {
                        label: 'Contact Us',
                        type: 'menu_item',
                        link: {
                            label: 'Contact us',
                            url: '/contact-us',
                        },
                    },
                    {
                        label: 'Careers',
                        type: 'menu_item',
                        link: {
                            label: 'Careers',
                            url: '/careers',
                        },
                    },
                    {
                        label: 'Trade Show Calendar',
                        type: 'menu_item',
                        link: {
                            label: 'Trade Shows',
                            url: '/trade-shows',
                        },
                    },
                    {
                        label: 'Credit Application Request',
                        type: 'menu_item',
                        link: {
                            label: 'Credit application request',
                            url: '/credit-application',
                        },
                    },
                ],
            },
            {
                items: [
                    {
                        title: 'Boring Stuff',
                        type: 'menu_item_header',
                    },
                    {
                        label: 'Terms & Conditions',
                        type: 'menu_item',
                        link: {
                            label: 'Terms & Conditions',
                            url: '/terms-and-conditions',
                        },
                    },
                    {
                        label: 'Privacy Policy',
                        type: 'menu_item',
                        link: {
                            label: 'Privacy Policy',
                            url: '/privacy-policy',
                        },
                    },
                    {
                        label: 'Shipping Policy',
                        type: 'menu_item',
                        link: {
                            label: 'Shipping Policy',
                            url: '/shipping-policy',
                        },
                    },
                    {
                        label: 'Refund & Return Policy',
                        type: 'menu_item',
                        link: {
                            label: 'Refund & Return Policy',
                            url: '/return-policy',
                        },
                    },
                    {
                        label: 'US State Sales Tax',
                        type: 'menu_item',
                        link: {
                            label: 'US State Sales Tax',
                            url: '/us-state-sales-tax',
                        },
                    },
                    {
                        label: 'Sitemap',
                        type: 'menu_item',
                        link: {
                            label: 'Sitemap',
                            url: '/sitemap',
                        },
                    },
                ],
            },
        ],
    },
];
